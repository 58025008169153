/**
 * Events list
 */
export default {
  EXPIRY: "expiry",
  ADD: "add",
  GET: "get",
  REMOVE: "remove",
  CLEAR: "clear"
};
